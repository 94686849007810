body {
  background: #eee;
  margin: 0;
  font-family: 'Fira Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0px;
}

a {
  color: #4ebf37;
  text-decoration-line: none;
  font-weight: 600;
}
a:hover {
  color: #43a72f;
}

button:disabled {
  opacity: 0.5;
}

li {
  padding: 0;
  margin: 0 0 15px;
}
